$(document).ready(function () {

	JS.Responsive
        .addHorizontalSizePoint( 'x-small', 370 )
        .addHorizontalSizePoint( 'small', 580 )
        .addHorizontalSizePoint( 'medium', 769 )
        .addHorizontalSizePoint( 'large', 960 )
        .addHorizontalSizePoint( 'x-large', 1080 )
        .addHorizontalSizePoint( 'xx-large', 1441 );

	var $window = $(window),
		$document = $(document),
		$html = $('html');

	// dynamic resize (document font size)
	$window.resize(function () {
		//console.log("resized");
		var etalon = 1320,
			w = $window.width(),
			h = $window.height(),
			koef = w / h,
			size = w,
			diff = Math.abs(etalon - size),
			sign = size > etalon ? 1 : -1,
			finalSize = etalon + Math.pow(diff, 1) * sign;

		// zastavenie zvacsovania nad etalon
		if (finalSize > etalon)
			finalSize = etalon;

		finalSize = (finalSize / etalon) * 100;

		$html.css('fontSize', (finalSize).toFixed(4) + 'px');

		// aj to hned raz spustime (necakame na resize)
	}).trigger('resize');

    $(document).on('submit', '#generator-form', function(e) {

        e.preventDefault();

        if ($('#generator-field-name').val() == '') { 
	      $('#generator-field-name').parent().addClass('highlight');
	    } else {
	    	$.request('onSend', {
	            data: $('#generator-form').serializeArray(),
	            success: function(msg) {
	                msg = JSON.parse(msg);
	                console.log(msg);

	                $('body').addClass('disable-scroll');
	                
	                $("body").on('scroll touchmove mousewheel', function(e){
						e.preventDefault();
					});

	                if ($('html').is('.medium-less')) {
	                	$('.stroj .middle').hide();
	                	$('body').addClass('mobile-animation');
	                	$('.left, .storm').addClass('animation');
	                }

	                $('#generator-popup .sentence p').text(msg.sentence);
	                $('#generator-popup .buttons .fb-share').attr('href', 'http://www.facebook.com/sharer.php?u=' + msg.share);
	                if (msg.image_url) {
	                	$('<img src="'+ msg.image_url +'">').insertAfter('.sentence');
	                }

	                realSec = 800,
					realHour = 2000000,
					realMin = 30000;

	                animateObjects()
	    			
	    			if($('html').is('.medium-more')) {
	    				$('.stroj, .storm, .buttons, .lines, .stroj .right').addClass('animation');
	    			}

	    			setTimeout(function(){
	    				$('#generator-popup').fadeIn(100);

	    				if ($('html').is('.small-less')) {
	    					$('#generator-popup .red-btn').prependTo($('#generator-popup'));
	    				}

	    				$("body").off('scroll touchmove mousewheel');


	    				setTimeout(function(){
	    					realSec = 6,
					    	realHour = 30,
					    	realMin = 6;
		    				$('.stroj, .storm, .buttons, .lines, .stroj .right, .left').removeClass('animation');

		    				$('#generator-popup .sentence-wrapper').removeClass('fade-out');
	    				}, 300);
	    			}, 7000);
	            }
	        });
	    }
    });


    // po kliku na button nechcem taku buducnost zobrazim okno s formularom
    $('#generator-popup .red-btn').on('click', function(){
    	$('.sentence-wrapper').addClass('fade-out ');

    	if ($('html').is('.small-less')) {
    		$(this).prependTo($('#generator-popup .buttons'));
    	}
    	
    	setTimeout(function(){
    		$('.sentence-wrapper').hide();
    		$('#generator-popup .profesia-days').removeClass('fade-out');
    	}, 800);
    });


    $('#mobile-button').on('click', function(){
    	$('.stroj .middle').addClass('down');
    });


    //hodiny - zobrazujem realny cas
    var realSec = 6,
    	realHour = 30,
    	realMin = 6;
    window.requestAnimFrame = (function(){
	  return  window.requestAnimationFrame       ||
	          window.webkitRequestAnimationFrame ||
	          window.mozRequestAnimationFrame    ||
	          function( callback ){
	              window.setTimeout(callback, 1000 / 60);
	          };
	})();

	//initialize the clock in a self-invoking function
	if ($('#clock').length) {
		(function clock(){ 
		    var hour = document.getElementById("hour"),
		        min = document.getElementById("min"),
		        sec = document.getElementById("sec");
		    //set up a loop
		    (function loop(){
		        requestAnimFrame(loop);
		        draw();
		    })();
		    //position the hands
		    function draw(){
		        var now = new Date(),//now
		            then = new Date(now.getFullYear(),now.getMonth(),now.getDate(),0,0,0),//midnight
		            diffInMil = (now.getTime() - then.getTime()),// difference in milliseconds
		            h = (diffInMil/(1000*60*60)),//hours
		            m = (h*60),//minutes
		            s = (m*60);//seconds
		        //rotate the hands accordingly
		        sec.style.webkitTransform = "rotate(" + (s * realSec) + "deg)";
		        hour.style.webkitTransform = "rotate(" + (h * realHour + (h / 2)) + "deg)";
		        min.style.webkitTransform = "rotate(" + (m * realMin) + "deg)";
		    } 
		})();
	}

	// animacia objektov v stroji
	var objects = $('#objects .object').length;

	
	function animateObjects() {
		for (var i = 0; i <= objects; i++) {
			(function(i) {
		       setTimeout(function(){
		       	$('#clock').fadeOut();
		       	$('#objects .object').removeClass('current');
		       	$('#objects .object').eq(i).addClass('current');
		       }, 2000 + (600 * i));
		   	})(i);
		}

		setTimeout(function(){
			$('#clock').fadeIn();
		}, 2000 + (objects * 600));
		
	}
	

});